.kiosk-pin-window {
    border-radius: 14px !important;
    height: auto !important;
    background: #f8f9fa;

    .k-window-titlebar {
        background: #fff;
        color: black;
        padding: 12px 25px 12px 20px;
        border-top-left-radius: 11px !important;
        border-top-right-radius: 11px !important;
        background: linear-gradient(180deg, #874FF6 0%, #6923F4 100%);
    }

    .k-window-content {
        padding: 0;
        padding-bottom: 0 !important;
        overflow: hidden;
        background: #f8f9fa;
        border-bottom-left-radius: 11px !important;
        border-bottom-right-radius: 11px !important;
    }

    .kiosk-pin-title {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
    }

    .pincode-input-container {
        margin: 16px 0;

        .pincode-input-text {
            width: 60px !important;
            height: 60px !important;
            border-color: #E1E2E8 !important;
            border-radius: 10px;
            background: #fff !important;
            font-size: 26px;
            font-weight: bold;
            color: transparent;
            text-shadow: 0 0 0 black;

            &:focus {
                outline: none;
            }
        }
    }

    .error-container {
        color: #272431;
        background-color: #FED9D5;
        margin: 0 12px 16px 12px;
        padding: 5px 0;

        .error-icon {
            background-image: url("../../../assets/error-icon-18.png");
            background-position: center;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
        }

        .error-text {
            font-size: 14px;
            font-weight: 500;
            border-radius: 3px;
            text-align: center;
        }
    }
}