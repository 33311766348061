#root {
    .member-panel-container {
        position: absolute;
        top: 0;
        height: 100%;
        width: 80%;
        border-radius: 40px 0 0 40px;
        right: 0;
        background: #f8f9fa;
        display: flex;
        flex-direction: column;
        z-index: 10;
        
        &>.header {
            background: linear-gradient(180deg, #874FF6 0%, #6923F4 100%);
            border-radius: 40px 0 0 0;
            height: 145px;
            display: flex;
            align-items: center;

            .signout-button-container {
                height: 100%;
                position: relative;

                .btn-sign-out {
                    right: 42px;
                    top: 35%;
                }

                .idle-timer {
                    position: absolute;
                    bottom: 15px;
                    right: 35px;
                    width: 265px;
                    font-size: 16px;
                    color: #fff;
                    letter-spacing: 1px;
                }
            }
        }
    }
}