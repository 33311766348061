.attend-confirmation-window {
    border-radius: 10px;
    height: auto !important;

    .k-window-titlebar {
        background: #fff;
        color: black;
        padding: 12px 25px 12px 20px;
    }

    .k-window-content {
        padding: 0;
        padding-bottom: 0 !important;
        overflow: hidden;
    }

    .attend-title {
        padding: 5px 0;
        font-size: 20px;
        font-weight: 600;
        color: #37b88f;

        .k-icon {
            background-image: url("../../assets/check-circle-green.png");
            background-position: center;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }

    .content {
        padding: 16px 24px 10px 24px;
    }

    .footer {
        height: 70px;
        line-height: 70px;
        text-align: right;
        position: relative;

        .btn-sign-out {
            left: 16px;
            bottom: 16px;
        }

        .idle-timer {
            position: absolute;
            left: 150px;
            font-size: 14px;

            .it-minutes,
            .it-seconds {
                font-weight: 600;
            }
        }

        .btn-print,
        .btn-email {
            height: 40px;
            width: 95px;
            background-image: none !important;
            margin-right: 16px;

            .btn-print-icon,
            .btn-email-icon {
                background-position: center;
                background-repeat: no-repeat;
                width: 15px;
                height: 15px;
                margin-right: 5px;
            }

            .btn-print-icon {
                background-image: url("../../assets/buttons/print-16.png");
            }

            .btn-email-icon {
                background-image: url("../../assets/buttons/email-16.png");
            }
        }
    }
}