.list-item {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-top: 12px;
    padding: 12px;

    &>div {
        border-left: 2px solid #6923F4;
        padding-left: 8px;
    }

    .start-time {
        font-size: 24px;
        font-weight: 600;
    }

    .title {
        font-size: 20px;
        font-weight: 600;
        color: #6a6d7d;
    }

    .spots-left {
        font-size: 13px;
        color: #6a6d7d;
        text-align: end;
    }

    .col-btn-attend {
        text-align: end;

        .btn-attend {
            height: 45px;
            width: 130px;
            background: #F99170;
            font-size: 20px;
            font-weight: 800;
            color: #272431;
        }
    }

    .class-info-row {
        .class-info-item {
            background: #EBE5FF;
            border-radius: 17px;
            padding: 6px 12px 6px 34px;
            font-size: 14px;
            font-weight: 500;
            margin-right: 16px;
            position: relative;
        }

        .class-info-item:before {
            content: "";
            background-position: center;
            background-repeat: no-repeat;
            width: 18px;
            height: 18px;
            position: absolute;
            left: 10px;
            top: 5px;
        }

        .instructor:before {
            background-image: url("../../assets/member-icon-16.png");
        }

        .rank:before {
            background-image: url("../../assets/rank-18.png");
        }

        .facility:before {
            background-image: url("../../assets/facility-18.png");
        }

        .location:before {
            background-image: url("../../assets/location-18.png");
        }
    }
}