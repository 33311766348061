body,
html {
    height: 100%;
    margin: 0;
    font-family: "SF Pro Display", sans-serif;
    scroll-behavior: smooth;
}

#root {
    height: 100%;

    .kiosk-app-container {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        overflow: hidden;

        .modal-background {
            position: absolute;
            width: 100%;
            height: 100%;
            background: black;
            opacity: 0.4;
        }


        .kiosk-app-content {
            position: absolute;
            top: 10%;
            padding-left: 5%;

            .header {
                .company-logo {
                    max-height: 100px;
                    max-width: 500px;
                }

                .company-header-text {
                    font-size: 48px;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .entry-buttons {
                margin-top: 15%;
                color: black;
                text-decoration: none;
                display: block;

                .entry-button {
                    width: 100%;
                    height: 135px;
                    background: #fff;
                    border-radius: 14px;
                    position: relative;
                    cursor: pointer;

                    .button-icon-section {
                        height: 100%;
                        width: 21%;
                        background: linear-gradient(180deg, #874FF6 0%, #6923F4 100%);
                        border-radius: 14px 0 0 14px;
                        position: relative;
                        float: left;
                        margin-left: -2px;

                        .button-icon {
                            width: 40px;
                            height: 30px;
                            background-image: url("./assets/buttons/card-icon.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            top: 40%;
                            left: 33%;
                            position: absolute;
                        }

                        .bi.bi-gear {
                            top: 30%;
                            left: 32%;
                            position: absolute;
                            font-size: 40px;
                            color: #fff;
                        }
                    }

                    .button-text {
                        height: 100%;
                        float: left;
                        padding: 35px 0 0 25px;

                        .text {
                            font-size: 24px;
                            font-weight: bold;
                        }

                        .description {
                            margin-top: 10px;
                            color: #6a6d7d;
                            font-size: 14px;
                        }
                    }

                    .button-action-section {
                        position: absolute;
                        top: 40%;
                        right: 3%;

                        .button-action-icon {
                            width: 40px;
                            height: 30px;
                            background-image: url("./assets/buttons/arrow-right-25.png");
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }
            }
        }
    }


    .xpl-scrollbar::-webkit-scrollbar {
        width: 10px;
    }

    .xpl-scrollbar::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
        border-radius: 12px;
    }

    .xpl-scrollbar::-webkit-scrollbar-thumb {
        background-color: #6923F4;
        border-radius: 12px;
    }
}