.attend-receipt-window {
    height: auto !important;
    width: auto !important;

    .row {
        padding-left: 0;
        margin-left: 0;
        margin-right: 0;

        &>* {
            padding: 0;
            overflow-wrap: break-word;
        }
    }

    .k-window-titlebar {
        display: none;
    }

    .receipt-window-title {
        padding: 0 0 10px 0;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        border-bottom: 1px solid #e1e2e8;
    }

    .attendance-receipt {
        width: 140pt;

        .header {
            text-align: center;

            .receipt-title {
                font-size: 11pt;
                font-weight: 600;
            }
        }

        .content {
            margin-top: 20px;

            .class-detail-title {
                font-size: 8pt;
                font-weight: 600;
                text-align: center;
            }

            .class-detail-container {
                border-width: 1px 0px 1px 0px;
                margin-top: 6px;
                padding: 14px 0 14px 0px;
                border-color: #e1e2e8;
                border-style: solid;

                .class-date {
                    font-size: 8pt;
                    font-weight: 500;
                    margin-bottom: 5px;
                }

                .class-detail {

                    .class-time,
                    .class-title {
                        font-size: 8pt;
                    }

                    .row {
                        .col-text {
                            font-size: 8pt;
                        }

                        .col-icon {
                            width: auto;
                        }
                    }

                    .row-title {
                        margin-bottom: 10px;
                    }

                    .row-details {
                        margin-top: 5px;
                    }

                    .class-detail-img {
                        width: 8pt;
                        height: 9pt;
                    }
                }

                .class-detail.container {
                    padding: 0;
                }
            }

            .your-information-title {
                margin: 14px 0 10px 0;
                font-size: 11pt;
                font-weight: 600;
                text-align: center;
            }

            .your-information-container {
                padding: 0;
                font-size: 8pt;

                .row {
                    border-top: 1px solid #e1e2e8;
                    padding: 7px 0;
                }
            }
        }

        .footer {
            text-align: center;

            .organization-logo {
                height: 25pt;
                width: 105pt;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 80%;
                display: inline-block;
                margin-left: -20px;
            }
        }
    }
}