.k-form-field {
    margin-top: 1em;

    .k-label {
        font-size: 16px;
        font-weight: 500;
    }

    .k-input {
        font-size: 20px;
        border-radius: 4px;

        .k-icon {
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 5px;
        }
    }

    .k-input-inner {
        padding: 11px 12px !important;
    }
}