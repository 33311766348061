.btn-sign-out {
    position: absolute !important;
    height: 40px;
    width: 120px;
    background-image: none !important;

    .btn-sign-out-icon {
        background-image: url("../../assets/lock-15.png");
        background-position: center;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }
}