.class-detail {
    .row {
        .col {
            padding: 0;
        }
    }

    .class-time {
        font-size: 24px;
        font-weight: 600;
    }

    .class-title {
        font-size: 20px;
        font-weight: 600;
        color: #6a6d7d;
        margin-top: 10px;
    }

    .row-title {
        margin-bottom: 20px;
    }

    .row-details {
        margin-top: 10px;

        &>span {
            width: auto;
            padding: 0;
        }

        .class-detail-img {
            width: 16px;
            height: 18px;
            padding: 0;
        }
    }

    .col-icon {
        margin-right: 5px;
        width: 20px;
    }

    .col-text {
        font-size: 14px;
        font-weight: 500;
        overflow-wrap: break-word;
    }
}