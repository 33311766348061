.kiosk-setup-window {
    border-radius: 14px !important;
    height: auto !important;
    background: #f8f9fa;

    .k-window-titlebar {
        background: #fff;
        color: black;
        padding: 12px 25px 12px 20px;
        border-top-left-radius: 11px !important;
        border-top-right-radius: 11px !important;
        background: linear-gradient(180deg, #874FF6 0%, #6923F4 100%);
    }

    .k-window-content {
        padding: 0;
        padding-bottom: 0 !important;
        overflow: hidden;
        background: #f8f9fa;
        border-bottom-left-radius: 11px !important;
        border-bottom-right-radius: 11px !important;
    }

    .kiosk-setup-title {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
    }

    .setup-item {
        margin: 10px 0 10px 0;
        font-size: 18px;

        .setup-item-text {
            padding-left: 5px;
        }

        .setup-error {
            color: #C0392B;
        }

        .setup-loading {
            color: #F4D03F;
        }

        .setup-loading:before {
            animation: rotation 3s infinite linear;
        }

        .setup-success {
            color: #58D68D;
        }

        .setup-error {
            color: #EC7063;
        }
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    .error-container {
        color: #272431;
        background-color: #FED9D5;
        margin: 0 12px 16px 12px;
        padding: 5px 0;

        .error-icon {
            background-image: url("../../../assets/error-icon-18.png");
            background-position: center;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
        }

        .error-text {
            font-size: 14px;
            font-weight: 500;
            border-radius: 3px;
            text-align: center;
        }
    }
}