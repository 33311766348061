#root {
    .member-attend-container {
        position: absolute;
        top: calc(50vh - 370px);
        height: 90%;
        width: 30%;
        border-radius: 50px 0 0 40px;
        right: 60px;
        display: flex;
        flex-flow: column;

        &>.header {
            background: linear-gradient(180deg, #874FF6 0%, #6923F4 100%);
            border-radius: 40px 40px 0 0;
            height: 325px;
            line-height: 100px;
            flex: 0 1 auto;
            text-align: center;

            .header-text {
                font-size: 32px;
                color: #fff;
                font-weight: 600;
            }

            .barcode-container {
                text-align: center;
                width: 200px;
                height: 170px;
                margin: 0 auto;
                background: #fff;
                border-radius: 20px;

                .bi.bi-upc-scan {
                    padding-top: 16px;
                    font-size: 100px;
                }

                .barcode-text {
                    line-height: 30px;
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 10px;
                }
            }

            .header-footer {
                line-height: 40px;
                font-size: 16px;
                color: #fff;
            }

            .btn-close {
                width: 20px;
                height: 20px;
                background-image: url("../../../assets/buttons/close-icon.png");
                background-repeat: no-repeat;
                background-position-y: center;
                height: 100%;
                float: right;
                margin-right: 5%;
                cursor: pointer;
            }
        }

        .login-section {
            padding: 30px 46px 30px 40px;
            background: #f8f9fa;
            border-radius: 0 0 40px 40px;
        }
    }
}