#root {
    .member-header-info-container {
        height: 100%;
        position: relative;
        flex: 1 0 auto;

        .member-photo {
            position: absolute;
            top: 28%;
            left: 42px;

            &>img {
                width: 69px;
                height: 69px;
                border-radius: 50px;
            }
        }

        .member-info {
            margin-left: 140px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            height: 100%;
            font-weight: 500;
            color: #fff;

            .name {
                font-size: 32px;
            }

            .description {
                font-size: 24px;
            }
        }
    }
}