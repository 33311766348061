.class-list-container {
    padding: 0 35px 0px 35px;
    margin-bottom: 10px;
    flex: 1;

    .class-list {
        padding: 0;
        margin: 35px 0 0 0;

        .list-header {
            font-size: 20px;
            font-weight: 600;
            color: #272431;
        }

        .list-items {
            padding: 0;
            margin-top: 10px;
        }
    }
}