.login-form {
    .k-messagebox {
        font-size: 14px;
        font-weight: 500;
        border: none;
    }

    .k-messagebox-error {
        color: #272431;
        background-color: #FED9D5;

        .message-icon {
            background-image: url("../../assets/error-icon-18.png");
            background-position: center;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            display: table-cell;
        }

        .message-text {
            display: table-cell;
            padding-left: 10px;
        }
    }

    &>.header {
        font-size: 20px;
        font-weight: 600;
        color: #272431;
        margin: 0 0 40px 0;
    }

    .login-input {
        .k-icon {
            background-image: url("../../assets/member-icon-16.png");
        }
    }

    .password-input {
        .k-icon {
            background-image: url("../../assets/buttons/lock-15.png");
            cursor: pointer;
        }
    }

    .forgot-password-btn {
        font-size: 16px;
        font-weight: 500;
        margin: 26px 0 38px 0;
        text-align: center;
        cursor: pointer;
    }

    .login-btn {
        height: 48px;
        border-radius: 39px;
        font-size: 20px;
        font-family: "SF Pro Display", sans-serif;
        text-transform: none;
        font-weight: 600;
        width: 100%;
        border: none;
    }

    .login-btn:disabled {
        background: #f3f4f6;
        color: #6a6d7d;
    }

    .login-btn:not(:disabled) {
        background: #f99170;
        color: #272431;
    }
}